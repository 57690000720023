export function useTranslation() {
    const currentLang = localStorage.getItem('i18nextLng') ?? 'az';
    const translationsList = JSON.parse(localStorage.getItem(`TRANSLATE${currentLang}`))
    const t = (key) => {
        return translationsList?.[key] ?? key;
    }
    return [t];
}

export default {
    useTranslation
};
