import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import PropTypes from "prop-types";
import "../../assets/css/_partnerSlider.scss";
import "react-fancybox/lib/fancybox.css";
import   {  Pagination, Autoplay } from "swiper";
import "swiper/swiper.min.css";

const PartnerSlider = ({ items, title, id }) => {
  return (
    <div className="partnerSlider" id={id}>
      <h2 className="title">{title}</h2>
     
           <Swiper
        slidesPerView={4}
        spaceBetween={30}
        slidesPerGroup={1}
        loop={true}
       
        loopFillGroupWithBlank={true}
        grabCursor={true}
        autoplay={{ delay: 2000, autoplayDisableOnInteraction: true }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          280: {
            slidesPerView: 2,
            width: 670,
            height: 100,
            slidesPerGroup: 1,
          },
          // when window width is >= 768px
          576: {
            slidesPerView: 2,
            width: 600,
            slidesPerGroup: 1,
          },
          600: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            width: 780,
          },
          800: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            width: 1200,
          },
          1020: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            width: 1300,
          },
        }}
        navigation={false}
        modules={[  Autoplay]}
        className="partnerSwiper"
      >
          {items.map((item,i) => (
           
            
           <div key={i} className="productSlider__items">
           <div  className="productSlider__items--img">
           <SwiperSlide className="partnerSliderContent">
               <a href={item.link} target="_blank"> <img className="partner-slider-img" src={item.image} alt={item.image} /></a>
           
            </SwiperSlide>
            
           
           </div>
         </div>
               
        
        ))}
        
       
      </Swiper>
        
     
    </div>
  );
};

PartnerSlider.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default PartnerSlider;