import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import {initReactI18next} from "react-i18next";
// baseurl
import {baseUrl} from "../services/api";

const trans = JSON.parse(
    localStorage.getItem(`TRANSLATE${localStorage.getItem("i18nextLng")}`)
);


const resources = {
    [localStorage.getItem("i18nextLng")]: {
        translation: trans,
    },
};


i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: localStorage.getItem("i18nextLng"),
        detection: {
            order: ["localStorage"],
            caches: ["localStorage"],
        },
        backend: {
            loadPath: baseUrl + "translation/{{lng}}",
            backends: [
                LocalStorageBackend,  // primary backend
            ],
            backendOptions: [{}]
        },
        // debug: process.env.NODE_ENV !== "production",
        debug: false,
        lng: window.location.pathname,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
        resources
    });

export default i18n;
