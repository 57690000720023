import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../assets/css/_homePage.scss";
import { Container } from "reactstrap";
import ProductSlider from "../components/sliders/productSlider";
import MultiSlider from "../components/sliders/multiSlider";
import NewsSlider from "../components/sliders/newsSlider";
import { useQuery } from "react-query";
import Title from "../title/title";
import { useTranslation } from "../i18/translations";
import { init } from "../queries/queries";
import CountUp from "react-countup";
import PartnerSlider from "../components/sliders/partnerSlider";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Autoplay, Navigation } from "swiper";

const HomePage = () => {
  const [t] = useTranslation();
  const currentLang = localStorage.getItem("i18nextLng")

  const { data, isLoading } = useQuery(["init", ""], init, {
    refetchOnWindowFocus: false,
  });

  return (
    <div className="homePage">
      <Title>
        <title>Bolluq</title>
      </Title>
      <div className="homePage__slider">
        <div className="homePage__sliderLayout"></div>
        <Swiper 
        navigation={false}
        autoplay={{delay:3000}}
        
         modules={[Navigation, Autoplay]}
        
          className="mySwiper_homePage__slider">
        
        {isLoading === false &&
          data !== undefined &&
          data.sliders.map((item) => (
            <SwiperSlide className="home_slide_swiper" key={item.id}> 
            <img src={item.image} alt={item.id} />
            </SwiperSlide>
          ))}
      </Swiper>
      </div>
      <Container>
        <div className="homePage__count d-flex align-items-center">
          {isLoading === false &&
            data !== undefined &&
            data.statistics.map((item) => (
              <div
                key={item.id}
                className="homePage__count--item d-flex justify-content-center align-items-center flex-column"
              >
                <p>
                  <img src={item.image} alt={item.title} />
                </p>
                <p>{item.title}</p>
                <p>
                  <CountUp end={item.count} duration={2} />{" "}
                  {item.increment && "+"}
                </p>
              </div>
            ))}
        </div>
        {isLoading === false && data !== undefined && data.products && (
          <ProductSlider items={data.products} />
        )}
        {isLoading === false && data !== undefined && data.certificates && (
          <MultiSlider items={data.certificates} title={t("sertifikatlar")} />
        )}
        {isLoading === false && data !== undefined && data.news && (
          <NewsSlider items={data.news} title={t("xeberler")} />
        )}
        {isLoading === false && data !== undefined && (
          <PartnerSlider
            items={data.partners}
            title={t("partnyorlar")}
            id={"endPartnerSlider"}
          />
        )}
      </Container>
    </div>
  );
};

export default HomePage;
